<template>
  <b-card
    class="card-congratulation-medal"
  >
    <b-button
      size="sm"
      style="margin-bottom: 15px;"
      variant="outline-primary"
      @click="ShowSupervisorRecord()"
    >
      Supervisor Record
    </b-button>
    <h5>Congratulations 🎉</h5>
    <b-card-text class="font-small-3">
      Your attitude is the most important.
    </b-card-text>
    <!-- User Stats -->
    <div class="d-flex align-items-center mt-2">
      <div class="d-flex align-items-center mr-2">
        <b-avatar
          variant="light-primary"
          rounded
        >
          <feather-icon
            icon="DollarSignIcon"
            size="18"
          />
        </b-avatar>
        <div class="ml-1">
          <h5 class="mb-0">
            {{ advancedInfo.TotalEvaluations }}
          </h5>
          <small>Forms Applied</small>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <b-avatar
          variant="light-success"
          rounded
        >
          <feather-icon
            icon="TrendingUpIcon"
            size="18"
          />
        </b-avatar>
        <div class="ml-1">
          <h5 class="mb-0">
            {{ advancedInfo.AverageScore }}
          </h5>
          <small>Average</small>
        </div>
      </div>
    </div>
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal MedalIcon"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BAvatar, BImg, BButton,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import 'animate.css'

export default {
  components: {
    BCard,
    BCardText,
    BAvatar,
    BImg,
    BButton,
  },
  props: {
    advancedInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    ShowSupervisorRecord() {
      this.$bus.$emit('setParamsRecordSupervisor', { idAgent: this.$store.getters['profiles/getIdProfile'] })
      this.$root.$emit('bv::show::modal', 'modal-supervisor-historical-record')
    },
  },
}
</script>

<style>
@media (max-width: 440px){
    .MedalIcon{
        display: none;
    }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

</style>
