<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-supervisor-historical-record"
    title="Supervisor Record"
    no-close-on-backdrop
    :hide-footer="true"
    scrollable
  >
    <b-card
      class="card-profile"
      style="margin-top:25px; margin-right: 20px; margin-left: 20px; background-color: #F9F9F9;"
    >
      <div
        class="profile-image-wrapper"
        style="margin-bottom: -35px;"
      >
        <div
          class="profile-image p-0"
          style="margin-top: 40px;"
        >
          <b-avatar
            size="35"
            variant="light"
          />
        </div>
      </div>
      <small>{{ Changes.length ? Changes[0].AgentName : 'No Record Found' }}</small>
      <hr class="mb-2">
    </b-card>
    <app-timeline>
      <app-timeline-item
        v-for="(logChange, index) in Changes"
        :key="index"
        icon="CalendarIcon"
        variant="success"
      >
        <div class="d-flex align-items-start flex-sm-row flex-wrap justify-content-between mb-1 mb-sm-0">
          <small>Actual Supervisor Name: <b><span class="text-info">{{ logChange.ActualSupervisorName }}</span></b> </small>
        </div>
        <div style="display: grid; margin-top: 15px; margin-bottom: 15px;">
          <small>Previous Supervisor Name: <b><span class="text-info">{{ logChange.PreviousSupervisorName }}</span></b> </small>
          <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
            <small>Updated By: <b><span class="text-info">{{ logChange.UserName }}</span></b></small>
            <div>
              <feather-icon
                icon="MessageSquareIcon"
                class="mr-1"
              />
            </div>
          </div>
          <small class="block text-muted">Changed On: {{ logChange.CreatedOn | datetime-format }}  </small>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BCard, BAvatar,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import profileService from '@core/services/profiles/profileService'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BModal,
    BCard,
    BAvatar,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      idAgent: 0,
      Changes: [],
    }
  },
  created() {
    this.$bus.$on('setParamsRecordSupervisor', param => {
      const { idAgent } = param
      this.idAgent = idAgent
      this.getHystoryofSupervisor()
    })
  },
  methods: {
    getHystoryofSupervisor() {
      profileService.getHistoryOfSupervisorRecord({
        idUser: this.idAgent,
      }).then(response => {
        this.Changes = response.data.logs ? response.data.logs : []
      })
    },
  },
}
</script>
