<template>
  <div class="h-100">
    <b-card
      v-if="evalutionOptions"
      no-body
      class="card-revenue-budget"
    >
      <b-row class="mx-0">
        <b-col
          lg="8"
          cols="12"
          class="revenue-report-wrapper"
        >
          <div class="d-sm-flex justify-content-between align-items-center mb-1">
            <h4 class="card-title mb-50 mb-sm-0">
              Evaluations
            </h4>
          </div>
          <b-row style="margin-bottom: 0px !important;">
            <b-col
              cols="12"
              md="5"
            >
              <div>
                <multiselect
                  v-model="FormSelected"
                  :options="evalutionOptions"
                  :searchable="true"
                  track-by="Name"
                  :multiple="true"
                  placeholder="Select Evaluations"
                  label="Name"
                  :max-height="150"
                  :option-height="30"
                  style="width: 100%; font-size: 10px; margin-bottom: 0px !important;"
                  class="mb-2"
                >
                  <template
                    slot="tag"
                    slot-scope="props"
                  >
                    {{ props.value }}
                  </template>
                  <template
                    slot="noResult"
                  >
                    <div style="padding: 0px !important; font-size: 10px;">
                      No Elements Found
                    </div>
                  </template>
                  <template
                    slot="selection"
                    slot-scope="{ values, isOpen }"
                  >
                    <span
                      v-if="values.length && !isOpen"
                      class="multiselect__single"
                      style="font-size: 12px"
                    >{{ values.length }} Evaluations selected</span>
                  </template>
                </multiselect>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="5"
            >
              <flat-pickr
                v-model="rangeDate"
                class="form-control mb-1"
                :config="{ mode: 'range', dateFormat: 'm-d-Y' }"
              />
            </b-col>
          </b-row>
          <!-- size -->
          <!-- chart -->
          <b-table
            :fields="fields"
            :items="items"
          >
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <feather-icon
                  :id="`invoice-row-${data.item.idEvaluation}-send-icon`"
                  icon="EyeIcon"
                  class="cursor-pointer"
                  size="16"
                  @click="navigateToEditEvaluationUser(data.item.idEvaluation)"
                />
              </div>
            </template>
          </b-table>
          <div
            v-if="Object.keys(FormSelected || {}).length === 0"
            style="text-align: center;"
          >
            <h5 style="margin-bottom: 0px; margin-top: 25px;">
              No Evalution Selected
            </h5>
            <b-img
              style="width: 278px;"
              :src="require('@/assets/images/pages/login-v2.svg')"
            />
          </div>
        </b-col>
        <b-col
          lg="4"
          cols="12"
        >
          <div
            v-if="!EvaluationSelection"
            style="display: grid; justify-content: center; margin: 50px; margin-top: 85px;"
          >
            <h5 style="margin-bottom: 25px;">
              No Specific Evalution Selected
            </h5>
            <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
          </div>
          <div v-if="EvaluationSelection">
            <b-card>
              <b-button
                v-if="EvaluationSelection"
                size="sm"
                variant="primary"
                style="margin-bottom: 10px; margin-left: 15px; margin-top: 15px; position: absolute;"
                @click="cleanSelection"
              >
                <span class="align-middle">Clean Selection</span>
                <feather-icon
                  icon="Trash2Icon"
                  class="ml-50"
                />
              </b-button>
              <b-button
                v-b-popover.focus="`${EvaluationInfo.Description}`"
                variant="primary"
                size="sm"
                style="margin-bottom: 10px; margin-left: 15px; margin-top: 50px; position: absolute;"
              >
                Notes
              </b-button>
              <b-button
                v-if="false"
                variant="primary"
                size="sm"
                style="margin-bottom: 10px; margin-left: 90px; margin-top: 50px; position: absolute;"
                @click="navigateToEditEvaluationUser"
              >
                Edit
              </b-button>
              <div class="bg-light-primary rounded-top text-center">
                <b-img
                  :src="require('@/assets/images/illustration/email.svg')"
                  alt="Meeting Pic"
                  height="100"
                />
              </div>
              <b-card-body>
                <!-- metting header -->
                <div
                  class="meetup-header d-flex align-items-center"
                  style="margin-bottom: 15px;"
                >
                  <div class="my-auto">
                    <b-card-title class="mb-25">
                      {{ EvaluationInfo.EvaluationName }}
                    </b-card-title>
                    <b-card-title class="mb-25">
                      {{ EvaluationInfo.Registration | date-format }}
                    </b-card-title>
                    <b-card-text class="mb-0">
                      Applied By: {{ EvaluationInfo.AppliedBy }}
                    </b-card-text>
                    <b-card-text
                      v-if="EvaluationInfo.Duration"
                      class="mb-0"
                    >
                      Duration: {{ EvaluationInfo.Duration | datetime-format }}
                    </b-card-text>
                  </div>
                </div>
                <!--/ metting header -->

                <!-- media -->
                <vue-perfect-scrollbar class="anotherscrollvueheight">
                  <b-card-text
                    v-for="(section, index) in evaluationScores"
                    :key="index"
                  >
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                    <b> Section :  {{ section.title }}  {{ section.value }}% </b>
                    <b-media
                      v-for="(scores, indexi) in section.questions"
                      :key="indexi"
                      no-body
                      style="margin-bottom: 5px;"
                      class="mt-1"
                    >
                      <b-media-aside class="mr-1">
                        <b-avatar
                          rounded
                          variant="light-primary"
                          size="34"
                        >
                          <feather-icon
                            icon="FileTextIcon"
                            size="18"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body>
                        <h6 class="mb-0">
                          {{ scores.Concept }}
                        </h6>
                        <small>{{ (scores.Score).toFixed(2) }} % </small>
                      </b-media-body>

                    </b-media>
                  </b-card-text>

                </vue-perfect-scrollbar>
              </b-card-body>
            </b-card>
          </div>

        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BButton, BImg, BAvatar, BMediaBody, VBPopover, BTable,
} from 'bootstrap-vue'
// BListGroup, BListGroupItem, BBadge,
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import VueApexCharts from 'vue-apexcharts'
import Multiselect from 'vue-multiselect'
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import 'swiper/css/swiper.css'
import evaluationsService from '@core/services/evaluations/evaluationService'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import HeadingSelected from './HeadingSelected.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    // BListGroup,
    // BListGroupItem,
    // VueApexCharts,
    Multiselect,
    VuePerfectScrollbar,
    // BBadge,
    flatPickr,
    // BButtonGroup,
    BButton,
    BTable,
    // Swiper,
    // SwiperSlide,
    // BFormSelect,
    // HeadingSelected,
    BImg,
  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      humanDate: null,
      permission: true,
      rangeDate: null,
      EvaluationSelection: null,
      FormSelected: null,
      evalutionOptions: [],
      selected: null,
      options2: [
        { value: 'this_week', text: 'This Week' },
        { value: 'last_week', text: 'Last Week' },
        { value: 'this_month', text: 'This Month' },
        { value: 'last_month', text: 'Last Month' },
        { value: 'this_six_months', text: 'This 6 Months' },
        { value: 'this_year', text: 'This Year' },
        { value: 'last_year', text: 'Last Year' },
        { value: 'all', text: 'All' },
      ],
      EvaluationInfo: null,
      evaluationScores: null,
      fields: [
        { key: 'idEvaluation', label: '#' },
        { key: 'name', label: 'Evaluation' },
        { key: 'date', label: 'Date', formatter: val => moment(val).format('MM-DD-YYYY') },
        { key: 'score', label: 'Score %' },
        { key: 'actions' },
      ],
      items: [],
    }
  },
  watch: {
    rangeDate(val) {
      if (val.split('to').length > 1) {
        const date = val.split('to')
        this.updateData('custom', date)
      }
    },
    selected(val) {
      this.updateData(val)
    },
    FormSelected(val) {
      if (val.length <= 0) {
        this.GraphicValueChange(0)
      } else {
        const idEvaluations = Object.values(val).map(data => data.Id)
        this.GraphicValueChange(idEvaluations)
      }
    },
  },
  mounted() {
    this.getUserEval()
    this.getPermission()
  },
  methods: {
    getEvaluationSelected(data) {
      const id = data.w.config.series[data.seriesIndex].data[data.dataPointIndex].idEvaluation
      this.EvaluationSelection = id
      evaluationsService.getUserEvaluationDetails({
        idEvaluation: this.EvaluationSelection,
      }).then(response => {
        const { evaluationInfo, evaluationScores } = response.data
        this.EvaluationInfo = evaluationInfo
        this.evaluationScores = this.formattScoresSections(evaluationScores)
      })
    },
    /* eslint-disable */
    formatEvaluations(data) {
      console.log(data)
      const convertedDataArray = []
      for (const { name, type, data: [{ x: date, y: score, idEvaluation }] } of data) {
        convertedDataArray.push({
          name, date, score, idEvaluation,
        })
      }
      return convertedDataArray
    },
    formattScoresSections(scoresQuestions) {
      const sectionScores = []
      scoresQuestions.forEach(question => {
        const {
          SectionDescription, SectionId, SectionTitle, SectionValue,
        } = question
        const sectionIndex = sectionScores.findIndex(score => score.id === SectionId)
        if (sectionIndex > -1) {
          sectionScores[sectionIndex].questions.push(question)
        } else {
          const section = {
            description: SectionDescription,
            id: SectionId,
            title: SectionTitle,
            value: SectionValue,
            questions: [],
          }
          section.questions.push(question)
          sectionScores.push(section)
        }
      })
      return sectionScores
    },
    getPermission() {
      const profile = this.$store.getters['user-auth/getStaffProfile']
      if (profile === 3) {
        this.permission = false
      }
    },
    navigateToEditEvaluationUser(EvaluationId) {
      this.$store.commit('evaluations/setIdEvaluationApplytoEdit', EvaluationId)
      this.$router.push({ name: 'evaluation-apply' })
    },
    GraphicValueChange(idEvaluations) {
      if (idEvaluations === 0) {
        this.Multiplechart.series = []
      } else {
        const idAgent = this.$store.getters['profiles/getIdProfile']
        evaluationsService.getUserAvgHeadingScore({
          idUser: idAgent,
          idEvaluations,
        }).then(response => {
          const { evaluations } = response.data
          this.items = this.formatEvaluations(evaluations)
        })
      }
    },
    cleanSelection() {
      this.EvaluationSelection = null
    },
    
    getUserEval() {
      this.$store.commit('evaluations/setIdEvaluationApplytoEdit', null)
      const idAgent = this.$store.getters['profiles/getIdProfile']
      this.updateData('this_six_months')
      evaluationsService.getUserEvaluations({
        id: idAgent,
      }).then(response => {
        const { evaluations } = response.data
        this.evalutionOptions = evaluations
        this.FormSelected = evaluations
      })
    },
    updateData(time, valcustom) {
      const weekStart = moment().startOf('isoWeek').format('MM-DD-YYYY')
      const weekEnd = moment().endOf('isoWeek').format('MM-DD-YYYY')
      const lastWeekStart = moment().subtract(1, 'weeks').clone().startOf('isoWeek')
        .format('MM-DD-YYYY')
      const lastWeekEnd = moment().subtract(1, 'weeks').clone().endOf('isoWeek')
        .format('MM-DD-YYYY')
      const monthStart = moment().startOf('month').format('MM-DD-YYYY')
      const monthEnd = moment().endOf('month').format('MM-DD-YYYY')
      const lastMonthStart = moment().subtract(1, 'month').startOf('month').format('MM-DD-YYYY')
      const lastMonthEnd = moment().subtract(1, 'month').endOf('month').format('MM-DD-YYYY')
      const SixMonthStar = moment().subtract(5, 'month').startOf('month').format('MM-DD-YYYY')
      const startYear = moment().startOf('year').format('MM-DD-YYYY')
      const EndYear = moment().endOf('year').format('MM-DD-YYYY')
      const lastStartYear = moment().subtract(1, 'year').startOf('year').format('MM-DD-YYYY')
      const lastEndYear = moment().subtract(1, 'year').endOf('year').format('MM-DD-YYYY')
      switch (time) {
        case 'this_week':
          this.rangeDate = `${weekStart} to ${weekEnd}`
          break
        case 'last_week':
          this.rangeDate = `${lastWeekStart} to ${lastWeekEnd}`

          break
        case 'this_month':
          this.rangeDate = `${monthStart} to ${monthEnd}`
          break
        case 'last_month':
          this.rangeDate = `${lastMonthStart} to ${lastMonthEnd}`
          break
        case 'this_six_months':
          this.rangeDate = `${SixMonthStar} to ${monthEnd}`
          break
        case 'this_year':
          this.rangeDate = `${startYear} to ${EndYear}`
          break
        case 'last_year':
          this.rangeDate = `${lastStartYear} to ${lastEndYear}`
          break
        default:
          break
      }
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.fs-8{
  font-size: 8px !important;
}

@media (min-width: 768px){
    .card-revenue-budget .lineBorder{
        border-right: 1px solid #ebe9f1;
    }
}
@media (max-width: 500px){
    .filterSelectEvaluations{
        display: block !important;
    }
    .filterButtonEvaluations{
        display: none;
    }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/swiper.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<style lang="scss" scoped>
.anotherscrollvueheight {
  height: 270px !important;
}
.ps-container {
  height: 180px;
}
</style>