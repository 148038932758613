<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-apply-evaluation"
    ref="modal-apply-evaluation"
    title="Apply Evaluation"
    no-close-on-backdrop
    :hide-footer="true"
    centered
    no-stacking
  >
    <div class="mb-2">
      <b-form-group
        label="Select the evaluation you want to apply"
        label-for="Select the evaluation you want to apply"
      >
        <b-form-select
          v-model="EvaluationSelected"
          label="Name"
        >
          <option
            v-for="(option,index) in evaluationOptions"
            :key="index"
            :value="option"
          >
            {{ option.Name }}
          </option>
        </b-form-select>
      </b-form-group>
    </div>
    <div class="mb-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        block
        variant="primary"
        @click="ApplyEvaluationSelected"
      >
        Apply
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BFormSelect, BFormGroup, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import evaluationService from '@core/services/evaluations/evaluationService'
import Swal from 'sweetalert2'

export default {
  components: {
    BModal,
    BFormSelect,
    BFormGroup,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      EvaluationSelected: null,
      evaluationOptions: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Simple Option' },
        { value: 'c', text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled' },
      ],
      idAgent: 0,
      idStaff: 0,
    }
  },
  created() {
    this.$bus.$on('setParamsApplyEvaluation', param => {
      const { idStaff } = param
      this.idStaff = idStaff
      this.getEvaluations()
    })
  },
  methods: {
    ApplyEvaluationSelected() {
      if (this.EvaluationSelected) {
        this.$store.commit('evaluations/setIdEvaluationApply', this.EvaluationSelected.Id)
        this.$router.push({ name: 'evaluation-apply' })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Missing',
          html: 'you dont select any evaluation to apply',
          showConfirmButton: false,
        })
      }
    },
    getEvaluations() {
      evaluationService.getEvaluationStaff({
        idStaff: this.idStaff,
      }).then(response => {
        if (response.data.evaluations.length <= 0) {
          this.evaluationOptions = [{ value: null, text: 'Not Evaluations yet to this User Staff' }]
        } else {
          this.evaluationOptions = response.data.evaluations
        }
      })
    },
  },
}
</script>
