<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-between">
          <div class="">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ simpleInfo.Name }}
              </h4>
              <span class="card-text">{{ simpleInfo.Email }}</span>
            </div>
          </div>
          <div><b-button
            v-if="permission"
            size="sm"
            variant="outline-primary"
            @click="ApplyEvaluation()"
          >
            Apply Evaluations
          </b-button></div>
        </div>
        <div class="d-flex align-items-center">
          <table class="w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Role</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ simpleInfo.Role }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Employee Number</span>
              </th>
              <td>
                {{ simpleInfo.EmployeeNumber }}
              </td>
            </tr>
          </table>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
  },
  props: {
    simpleInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      permission: true,
    }
  },
  created() {
    this.getPermission()
  },
  methods: {
    ApplyEvaluation() {
      this.$bus.$emit('setParamsApplyEvaluation', { idStaff: [this.simpleInfo.StaffId] })
      this.$root.$emit('bv::show::modal', 'modal-apply-evaluation')
    },
    getPermission() {
      const profile = this.$store.getters['user-auth/getStaffProfile']
      if (profile === 3) {
        this.permission = false
      }
    },
  },
}
</script>
