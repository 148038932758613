<template>
  <div>
    <!-- First Row -->
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="4"
        md="6"
      >
        <personal-info
          :simple-info="SimpleInfo"
        />
      </b-col>
      <b-col
        cols="12"
        xl="8"
        md="6"
      >
        <congratulations :advanced-info="AdvancedInfo" />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        lg="12"
      >
        <evaluation-report />
      </b-col>
    </b-row>
    <modal-apply-evaluation />
    <modal-supervisor-record />

  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import profileService from '@core/services/profiles/profileService'
import PersonalInfo from './PersonalInfo.vue'
import Congratulations from './Congratulations.vue'
import EvaluationReport from './EvaluationReport.vue'
import ModalApplyEvaluation from '../../../@core/components/modals/profile-info/ApplyEvaluationModal.vue'
import ModalSupervisorRecord from '../../../@core/components/modals/profile-info/SupervisorHistoricalRecord.vue'

export default {
  components: {
    BRow,
    BCol,
    PersonalInfo,
    Congratulations,
    EvaluationReport,
    ModalApplyEvaluation,
    ModalSupervisorRecord,
  },
  data() {
    return {
      SimpleInfo: {},
      AdvancedInfo: {},
      IdAgent: 0,
    }
  },
  created() {
    const idAgent = this.$store.getters['profiles/getIdProfile']
    this.IdAgent = idAgent
    this.getadvancedInfo()
    this.getSimpleInfo()
  },
  methods: {
    getSimpleInfo() {
      profileService.getSimpleUserInfo({
        id: this.IdAgent,
      }).then(response => {
        this.SimpleInfo = response.data.data
      })
    },
    getadvancedInfo() {
      profileService.getAdvancedUserInfo({
        id: this.IdAgent,
      }).then(response => {
        this.AdvancedInfo = response.data.data
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
</style>
